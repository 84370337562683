











import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { SfImage, SfLink, SfSection } from '@storefront-ui/vue';
import { useApi } from '~/composables';
import getPartnersListGql from '~/modules/catalog/product/queries/getPartnersList.gql';
import { Partner, PartnersQuery } from '~/modules/catalog/types';

export default defineComponent({
  name: 'OurPartnersSection',
  components: {
    SfSection,
    SfImage,
    SfLink,
  },
  setup() {
    const { query } = useApi();
    const partners = ref<Partner[]>();

    useFetch(async () => {
      const { data } = await query<PartnersQuery>(getPartnersListGql);
      partners.value = data.partners.items;
    });

    return { partners };
  },
});
