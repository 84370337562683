













import { defineComponent } from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';
import CustomSwiper from '~/components/custom-swiper/CustomSwiper.vue';
import CustomSwiperSlide from '../custom-swiper/CustomSwiperSlide.vue';

export default defineComponent({
  components: {
    CustomSwiper,
    CustomSwiperSlide,
    SfImage,
  },
  setup() {
    const slides = [
      {
        image: '/homepage/testimonials/hakan.webp',
        text: '"Blodanalyser är inte bara ett verktyg inom sjukvården för att detektera sjukdom. Inom idrottsvärlden har man i flera år använt blodanalyser för att styra, kontrollera och utvärdera prestationsförmåga. Dessa blodanalyser har oftast gjorts på idrottare inom elitklubbar eller motionseliten då de oftast har en stab av läkare, nutritionister och fystränare. Kunskapen kring idrott, prestation, kost och hälsoeffekter har vuxit de senaste åren och inte bara hos eliten utan även hos den tränande medelsvensson. Idag är det oftast tillgängligheten och kunskapen kring blodprov som är begränsande." \n ',
        name: 'Håkan Rundqvist',
        title: 'Träningsfysiolog (PhD, Msc, Fysiologi)',
      },
      {
        image: '/homepage/testimonials/tyler.webp',
        text: '"Efter att ha spenderat åtskilliga timmar med träning blev jag förvånad att jag inte fick den muskelutvecklingen jag förväntade mig. Efter ett enkelt test stod det klart vad jag saknade och vad jag behövde åtgärda!"',
        name: 'Tyler Crowley',
        title: 'Organizer STHLM TECH MEETUP',
      },
      {
        image: '/homepage/testimonials/ctherine.webp',
        text: '"Tack vare blodkollen har jag blivit mycket piggare och fått större insikt i hur min kropp mår. Tack för en bra tjänst!"',
        name: 'Cathrine Hardenborg',
        title: 'Modell & Egen företagare',
      },
      {
        image: '/homepage/testimonials/klotz.webp',
        text: '"Då prestation så nära 100% som möjligt är viktigt för oss i Hammarby, så kan låga nivåer av olika ämnen i kroppen ligga oss till last. Blodkollen gjorde det enkelt för oss att med deras professionella hjälp analysera och utvärdera vilka spelare som låg i riskzonen och som eventuellt kanske inte har optimala förutsättningar att nå upp till 100%. Blodkollen är ett fantastiskt verktyg för att kunna kontrollera och utvärdera detta på ett strikt empiriskt och vetenskapligt sätt."',
        name: 'Mikael Klotz',
        title: 'Medicinskt & REHAB ansvarig Hammarby Fotboll AB',
      },
      {
        image: '/homepage/testimonials/ref-2.webp',
        text: '"Idag så måste man i princip vara sjuk för att få sina värden kollade och det kan jag tycka är lite bakvänt."',
        name: 'Deivid Lopes Da Silva',
        title: 'Lic. Personlig tränare',
      },
      {
        image: '/homepage/testimonials/nadia_circular.webp',
        text: '"Ett mycket bra verktyg att hålla koll på sin hälsa och dessutom få användbara råd för att förbättra sin hälsa."',
        name: 'Nadja Öström',
        title: 'Lic. Kostrådgivare och ordf. sköldkörtelföreningen',
      },
      {
        image: '/homepage/testimonials/lovisa.webp',
        text: '"På Blodkollen är vi upplysande och ser oss som steget innan primärvården. Genom att ha med sig ett kvitto på hur man mår redan vid första läkarbesöket kan man skynda på processen avsevärt. Och många gånger behöver man inte gå till doktorn alls, till exempel om det visar sig att man har brist på något ämne och behöver tillskott. Blodkollen kan därmed också vara ett sätt att minska belastningen på primärvården." \n ',
        name: 'Lovisa Ryding',
        title: 'Grundare och Vd för Blodkollen, Bachelor of Medical Science',
      },
      {
        image: '/homepage/testimonials/frida.webp',
        text: '"Jag hade under väldigt lång tid känt att jag inte mått bra och varit på vårdcentralen och tagit prover som enligt läkaren inte visat någonting. Via Blodkollen fick jag hjälp och förståelse. Jag önskar att jag kunde förklara skillnaden som jag känner, det är obeskrivligt. Tänk att jag kunde hitta orsaken till allt ont!"',
        name: 'Frida Widén',
        title: 'Egen företagare',
      },
      {
        image: '/homepage/testimonials/nixon.webp',
        text: '"Jag har testat Blodkollen och fick så mycket hjälp och bra svar. Det konkurrerar ut Svensk sjukvård med råge."',
        name: 'Emelie Nixon',
        title: 'Egen företagare',
      },
    ];
    return { slides };
  },
});
