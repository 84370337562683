

















import { defineComponent } from '@nuxtjs/composition-api';
import { SfList } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'HowItWorks',
  components: {
    SvgImage,
    SfList,
  },
  setup() {
    const data = [
      {
        image: 'vial',
        title: 'Select blood analysis package',
        description: 'Choose an analysis package that suits you and go to checkout.',
        imageWidth: '35',
        imageHeight: '55',
      },
      {
        image: 'location-pinpoint',
        title: 'Book a lab near you',
        description: 'When you buy your analysis package, you choose a lab that is close to you.',
        imageWidth: '61',
        imageHeight: '61',
      },
      {
        image: 'results',
        title: 'Get your test results',
        description: 'You will receive an email when your test answers are available to download in my journal.',
        imageWidth: '73',
        imageHeight: '48',
      },
    ];
    return {
      data,
    };
  },
});
