export default `query getPartnersList(
	$search: String = null
	$pageSize: Int = 100
	$currentPage: Int = 1
	$filter: PartnerFilterInput
	)
{
	partners(
		search: $search
		pageSize: $pageSize
		currentPage: $currentPage
		filter: $filter
	) {
		items {
			logo
			slug
			name
			}
		}
}`;
