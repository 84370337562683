





















/* eslint-disable unicorn/prefer-ternary */
import { defineComponent, onMounted, provide, reactive, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CustomSwiper',
  setup(_, { slots }) {
    const slideSlots = ref([]);
    onMounted(() => {
      slideSlots.value = (slots.default() || []).filter((vnode) => vnode.tag);
    });

    const state = reactive({
      currentIndex: 0,
      swipeStartCoordinateX: 0,
      swipeStartCoordinateY: 0,
      swipeProgressCoordinateX: 0,
      isSwiping: false,
    });

    function startSwipe(event: MouseEvent | TouchEvent): void {
      // Calculate direction of swipe, only continue if horizontal
      let touchStartCoordinateX = 0;
      if (event.type === 'touchstart') {
        touchStartCoordinateX = (event as TouchEvent).touches[0].clientX;
      } else {
        touchStartCoordinateX = (event as MouseEvent).clientX;
      }

      let touchStartCoordinateY = 0;
      if (event.type === 'touchstart') {
        touchStartCoordinateY = (event as TouchEvent).touches[0].clientY;
      } else {
        touchStartCoordinateY = (event as MouseEvent).clientY;
      }

      state.swipeStartCoordinateX = touchStartCoordinateX;
      state.swipeStartCoordinateY = touchStartCoordinateY;

      state.isSwiping = true;
    }

    function checkIsSwipingXaxis(event: MouseEvent | TouchEvent): boolean {
      if (!state.isSwiping) return null;

      let clientX = 0;
      if (event.type === 'touchmove') {
        clientX = (event as TouchEvent).touches[0].clientX;
      } else {
        clientX = (event as MouseEvent).clientX;
      }

      let clientY = 0;
      if (event.type === 'touchmove') {
        clientY = (event as TouchEvent).touches[0].clientY;
      } else {
        clientY = (event as MouseEvent).clientY;
      }

      // Compare X and Y to see if user is trying to swipe or scroll
      const diffX = Math.abs(clientX - state.swipeStartCoordinateX);
      const diffY = Math.abs(clientY - state.swipeStartCoordinateY);
      return diffX > diffY;
    }

    function moveSlide(event: MouseEvent | TouchEvent): void {
      if (!state.isSwiping) return;
      const isSwipingXaxis = checkIsSwipingXaxis(event);
      if (!isSwipingXaxis) {
        state.isSwiping = false;
        return;
      }
      event.preventDefault();

      const clientX = event.type === 'touchmove' ? (event as TouchEvent).touches[0].clientX : (event as MouseEvent).clientX;

      let potentialProgress = clientX - state.swipeStartCoordinateX;

      // Applying a limitation after 400px and extreme slow down after 660px
      if (Math.abs(potentialProgress) > 400) {
        const sign = potentialProgress > 0 ? 1 : -1;
        const diff = Math.abs(potentialProgress) - 400;

        if (diff < 660) {
          potentialProgress = 400 * sign + (sign * diff) / 2;
        } else {
          potentialProgress = 400 * sign + sign * 65 + sign * Math.log(diff - 259);
        }
      }

      state.swipeProgressCoordinateX = potentialProgress;
    }

    function nextSlide(): void {
      if (state.currentIndex < slideSlots.value.length - 1) {
        state.currentIndex++;
      }
    }

    function previousSlide(): void {
      if (state.currentIndex > 0) {
        state.currentIndex--;
      }
    }

    function endSwipe(event: MouseEvent | TouchEvent): void {
      if (!state.isSwiping) return;
      state.isSwiping = false;
      const endCoordinateX = event.type === 'touchend' ? (event as TouchEvent).changedTouches[0].clientX : (event as MouseEvent).clientX;

      if (state.swipeStartCoordinateX > endCoordinateX + 70) {
        nextSlide();
      } else if (state.swipeStartCoordinateX + 70 < endCoordinateX) {
        previousSlide();
      }

      state.swipeProgressCoordinateX = 0;
    }

    function goToSlide(index: number): void {
      state.currentIndex = index;
    }

    function getTranslateX(index: number): number {
      // Calculate the total width of all previous slides
      let totalWidth = 0;
      for (let i = 0; i < index; i++) {
        totalWidth += slideSlots.value?.[i]?.clientWidth || 0;
      }

      // Factor in the current index and swipe progress
      let translateXOffset = totalWidth - state.currentIndex * 100;
      if (state.isSwiping && index === state.currentIndex) {
        const swiperWidth = document.querySelector('.swiper').clientWidth;
        translateXOffset += (state.swipeProgressCoordinateX / swiperWidth) * 100;
      }

      return translateXOffset;
    }

    provide('getTranslateX', getTranslateX);

    return {
      startSwipe,
      moveSlide,
      endSwipe,
      nextSlide,
      previousSlide,
      goToSlide,
      getTranslateX,
      state,
      slideSlots,
    };
  },
});
