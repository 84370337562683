






import { computed, defineComponent, inject } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CustomSwiperSlide',
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const getTranslateX: any = inject('getTranslateX');
    const translateX = computed(() => getTranslateX(props.index));

    return {
      translateX,
    };
  },
});
