






























import { defineComponent, onMounted, ref, useContext, useFetch } from '@nuxtjs/composition-api';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import { CmsPage } from '@vue-storefront/magento-api';
import AnalysisPackagesSection from '~/components/sections/AnalysisPackagesSection.vue';
import HeroSection from '~/components/sections/HeroSection.vue';
import HowItWorks from '~/components/sections/HowItWorks.vue';
import OurPartnersSection from '~/components/sections/OurPartnersSection.vue';
import TestimonialsSection from '~/components/sections/TestimonialsSection.vue';
import VideoSection from '~/components/sections/VideoSection.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useContent } from '~/composables';

export default defineComponent({
  name: 'HomePage',
  components: {
    HeroSection,
    HowItWorks,
    LoadWhenVisible,
    AnalysisPackagesSection,
    TestimonialsSection,
    OurPartnersSection,
    VideoSection,
  },
  setup() {
    const { addTags } = useCache();
    const { loadPage } = useContent();
    const { app } = useContext();
    const { isDesktop } = app.$device;

    const page = ref(null as CmsPage | null);

    const hero = ref({
      title: app.i18n.t('How does your body really feel on the inside?'),
      subtitle: app.i18n.t(
        'We want it to be easy to find out how we actually feel on the inside through a health check.',
      ),
      buttonText: app.i18n.t('Our blood tests'),
      imageSrc: '/homepage/hero-image.webp',
      imageSrcHover: '/homepage/hero-image-hover.webp',
      imageWidth: isDesktop ? 622 : 470,
      imageHeight: isDesktop ? 564 : 427,
      imageConfig: {
        fit: 'cover',
        format: 'webp',
      },
      link: '#analysis-packages',
    });

    useFetch(async () => {
      page.value = await loadPage({ identifier: 'home' });
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    return {
      hero,
      page,
    };
  },
});
