


































import { ImageModifiers } from '@nuxt/image';
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { useHoverImage } from '~/composables';

export default defineComponent({
  name: 'HeroSection',
  components: {
    SfButton,
    SvgImage,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    imageHeight: {
      type: [Number, String],
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    imageSrcHover: {
      type: String,
      default: '',
    },
    imageTag: {
      type: String,
      default: '',
    },
    imageWidth: {
      type: [Number, String],
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    nuxtImgConfig: {
      type: Object as PropType<ImageModifiers | {}>,
      default: () => ({}),
    },
  },

  setup() {
    const { isHovered, hoverImage, unhoverImage } = useHoverImage();
    return {
      isHovered,
      hoverImage,
      unhoverImage,
    };
  },
  methods: {
    scrollToElement() {
      const element = document.querySelector('.how-it-works');

      const rect = element.getBoundingClientRect();

      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      const targetPosition = rect.top + scrollTop;

      window.scrollTo({ top: targetPosition, behavior: 'smooth' });
    },

    goToLinkSection(elementId: string) {
      const element = document.querySelector(elementId);

      const rect = element.getBoundingClientRect();

      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // Calculate the position to scroll to (element's top position)
      const targetPosition = rect.top + scrollTop;

      window.scrollTo({ top: targetPosition, behavior: 'smooth' });
    },
  },
});
