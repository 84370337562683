
















import { defineComponent } from '@nuxtjs/composition-api';
import { SfSection } from '@storefront-ui/vue';

export default defineComponent({
  name: 'VideoSection',
  components: {
    SfSection,
  },
});
